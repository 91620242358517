import { CaseState, CaseStatusFilter } from "../schemas/ApiSchema";

export const CaseStateLabels: Record<CaseState, string> = {
  [CaseState.DELEGATED_TO_LIMS]: "Locked",
  [CaseState.AWAITING_SLIDES]: "Awaiting slides",
  [CaseState.READY_FOR_PATHOLOGIST]: "Waiting for pathologist",
  [CaseState.REPORT_SUBMITTED]: "Waiting for pathologist",
  [CaseState.LOCKED]: "Locked",
  [CaseState.WITH_THE_LAB]: "Unlocked for lab changes",
};

export const CaseStatusFilterLabels: Record<CaseStatusFilter, string> = {
  [CaseStatusFilter.ALL]: "All",
  [CaseStatusFilter.AWAITING_SLIDES]: "Awaiting slides",
  [CaseStatusFilter.READY_FOR_PATHOLOGIST]: "Waiting for pathologist",
  [CaseStatusFilter.PENDING_SPECIALS]: "Pending specials",
  [CaseStatusFilter.WITH_THE_LAB]: "Unlocked by lab",
  [CaseStatusFilter.LOCKED]: "Locked",
};

export const getCaseStateLabel = (
  caseState: CaseState,
  hasOpenSpecials: boolean
): string => {
  // Override the label if the case has blockers
  if (hasOpenSpecials) {
    return CaseStatusFilterLabels[CaseStatusFilter.PENDING_SPECIALS];
  }

  return CaseStateLabels[caseState];
};

export const getCaseStateDescription = (
  caseState: CaseState,
  hasOpenSpecials: boolean,
  pathologistName?: string
): string => {
  // Override the label if the case has blockers
  if (hasOpenSpecials) {
    return CaseStatusFilterLabels[CaseStatusFilter.PENDING_SPECIALS];
  }

  switch (caseState) {
    case CaseState.DELEGATED_TO_LIMS:
      return "This case cannot be managed in LabKit";
    case CaseState.LOCKED:
      // We can't yet be certain why the case was most recently locked
      return "";
    case CaseState.READY_FOR_PATHOLOGIST:
    case CaseState.REPORT_SUBMITTED:
      return (
        CaseStateLabels[caseState] + (pathologistName ? ` (${pathologistName})` : "")
      );
    default:
      return CaseStateLabels[caseState];
  }
};
